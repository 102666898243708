import { flowRight, isNumber, get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../runtime-context';
import classNames from 'classnames';
import memoizeOne from 'memoize-one';
import {
  SECTIONS,
  isLayoutNameTextOnImage,
  isLayoutNameList,
  isLayoutNamePGSideBySide,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_POST_CARD_ALIGNMENT } from '@wix/communities-blog-experiments';

import withExperiment from '../../hoc/with-experiment';
import PostListItemHeader from '../post-list-item-header';
import PostListItemProGalleryTitle from './post-list-item-pro-gallery-title';
import PostListItemProGalleryExcerpt from './post-list-item-pro-gallery-excerpt';
import PostFooter from '../post-footer';
import PostLink from '../link/post-link';
import { getFeedColorClassName } from '../../services/layout-config';
import { getContentAlignmentStyles } from '../../services/content-alignment-helpers';
import { HorizontalSeparatorForPostCard } from '../separator';
import withFeedBorderWidth from '../../hoc/with-feed-border-width';
import withFeedMetadataSettings from '../../hoc/with-feed-metadata-settings';
import withFontClassName from '../../hoc/with-font-class-name';
import withIsFeedDesignEnabled from '../../hoc/with-is-feed-design-enabled';
import withPostFontSize from '../../hoc/with-post-font-size';
import { getPostCoverImageSrc, getPostByIdOrSlug } from '../../selectors/post-selectors';
import { getIsRTL } from '../../store/basic-params/basic-params-selectors';
import { TEXT_PLACEMENT } from '../../constants/pro-gallery-options';
import {
  getContentAlignment,
  getLayoutDescriptionLineCount,
  getLayoutTitleLineCount,
} from '../../selectors/app-settings-selectors';
import PostListItemCategoryLabel from '../post-list-item-category-label';
import styles from '../../styles/post-list-item.scss';
import alignmentStyles from '../../styles/post-list-item-alignment.scss';
import {
  getShouldUsePostListMobileDescriptionFontSize,
  getShouldUsePostListMobileTitleFontSize,
} from '../../selectors/mobile-settings-selectors';

const getMetadataStyle = memoizeOne((postMetadataFontSize) => ({ fontSize: postMetadataFontSize }));
const getTitleStyle = memoizeOne((postTitleFontSize) => ({ fontSize: postTitleFontSize }));
const getDescriptionStyle = memoizeOne((postDescriptionFontSize) => ({ fontSize: postDescriptionFontSize }));
const empty = {};

export const PostListItem = ({
  type,
  alignment,
  post,
  onLikeClick,
  itemConfig,
  titleFontClassName,
  contentFontClassName,
  contentFontClassNameWithStyle,
  postTitleFontSize,
  postDescriptionFontSize,
  postMetadataFontSize,
  isMetadataFooterVisible,
  isMetadataHeaderVisible,
  canSeeMoreButton,
  showPostDescription,
  applyFeedDesign,
  getPostClassName,
  index,
  layoutSidesPadding,
  hideAuthorBadge,
  isRTL,
  galleryOptions = {},
  textBoxAlignment,
  section,
  descriptionLineCount,
  titleLineCount,
  navigateProGalleryWithinPostPage,
  showCategoryLabel,
  shouldUsePostListMobileDescriptionFontSize,
  shouldUsePostListMobileTitleFontSize,
  isPostCardAlignmentEnabled,
  visibleActions,
}) => {
  const showMoreButton = canSeeMoreButton(post);
  const { lineCount, displayFooterIcons } = itemConfig;

  const layoutList = isLayoutNameList(type);
  const isSideBySideLayout = isLayoutNamePGSideBySide(type);
  const withoutFooter = !isMetadataFooterVisible;
  const withDescription = showPostDescription && !layoutList;
  const containerClassName = classNames(
    styles.container,
    styles.proGallery,
    isRTL && styles.isRTL,
    styles[type],
    isPostCardAlignmentEnabled && alignmentStyles[alignment],
    withoutFooter && styles.withoutFooter,
    contentFontClassName,
    'blog-text-color',
    'blog-card-background-color',
    'blog-card-border-color',
    'post-list-item',
    !applyFeedDesign && !isLayoutNameTextOnImage(type) && 'blog-hover-container',
    !isLayoutNameTextOnImage(type) && getPostClassName('hover-container'),
    getPostClassName('border-color', 'post-container', getFeedColorClassName(type, 'background-color')),
  );

  const contentWrapperClassName = classNames(
    styles.contentWrapper,
    styles[type],
    isPostCardAlignmentEnabled && isRTL && alignmentStyles.isRTL,
    !applyFeedDesign && isLayoutNameTextOnImage(type) && styles.withBackground,
    withoutFooter && styles.withoutFooter,
    'post-list-item-wrapper',
    isLayoutNameTextOnImage(type) && getPostClassName('overlay-background-color'),
    getPostClassName(
      'description-font',
      getFeedColorClassName(type, 'description-color'),
      getFeedColorClassName(type, 'description-fill'),
    ),
  );

  const linkContainerClass = classNames(
    styles.textWrapper,
    getPostClassName('link-hashtag-hover-color'),
    {
      [styles.fadeout]: descriptionLineCount || titleLineCount,
    },
    styles[type],
    showCategoryLabel && styles.withCategoryLabel,
  );
  const titleLinkClassName = classNames(
    styles.titleWrapper,
    styles.proGallery,
    styles[type],
    showMoreButton ? styles.withShowMoreButton : styles.withoutShowMoreButton,
    'has-custom-focus',
    styles.customFocus,
  );
  const excerptLinkClassName = classNames(
    styles.excerptLink,
    styles.textWrapper,
    styles.proGallery,
    styles[type],
    showMoreButton ? styles.withShowMoreButton : styles.withoutShowMoreButton,
  );
  const titleContainerClassName = classNames(
    styles.title,
    titleFontClassName,
    styles[type],
    withoutFooter && styles.withoutFooter,
    !withDescription && withoutFooter && styles.withoutBottomMargin,
    !withDescription && styles.withoutDescription,
    post.isPinned && styles.withIcons,
    showMoreButton ? styles.withShowMoreButton : styles.withoutShowMoreButton,
  );
  const contentClassName = classNames(
    styles.content,
    styles[type],
    withoutFooter && styles.withoutFooter,
    contentFontClassNameWithStyle,
    getPostClassName('description-style-font'),
  );
  const headerClassName = classNames(
    contentFontClassName,
    getPostClassName('description-font'),
    alignmentStyles.headerContainer,
  );
  const footerClassName = classNames(
    getPostClassName('description-font', getFeedColorClassName(type, 'description-color')),
  );
  const titleStyle = shouldUsePostListMobileTitleFontSize ? getTitleStyle(postTitleFontSize) : empty;
  const contentStyle =
    !applyFeedDesign || shouldUsePostListMobileDescriptionFontSize
      ? getDescriptionStyle(postDescriptionFontSize)
      : empty;
  const containerStyle = {
    ...(getPostCoverImageSrc(post) && isLayoutNameTextOnImage(type) && { background: 'transparent' }),
    borderWidth: 0,
  };

  if (layoutList) {
    containerStyle.background = 'transparent';
    containerStyle.paddingTop = '12px';
    containerStyle.paddingBottom = '12px';
    textBoxAlignment === TEXT_PLACEMENT.SHOW_ON_THE_RIGHT
      ? (containerStyle.marginLeft = '14px')
      : (containerStyle.marginRight = '14px');
  }

  const getLayoutPaddingStyles = (layoutSidesPadding, layoutTextOnImage, isFooter = false) => {
    if (!layoutTextOnImage && isFooter) {
      return empty;
    }

    return isNumber(layoutSidesPadding)
      ? {
          paddingLeft: layoutSidesPadding,
          paddingRight: layoutSidesPadding,
          ...(isFooter && {
            left: 0,
            right: 0,
          }),
        }
      : {};
  };

  const Header = (
    <PostListItemHeader
      className={headerClassName}
      post={post}
      showMoreButton={showMoreButton}
      hideAuthorBadge={hideAuthorBadge}
      showProfileImage={!isLayoutNameTextOnImage(type)}
      style={getMetadataStyle(postMetadataFontSize)}
      type={type}
      isProGallery={true}
      alignment={alignment}
      visibleActions={visibleActions}
    />
  );

  const renderCategoryLabel = () =>
    showCategoryLabel && (
      <PostListItemCategoryLabel className={alignmentStyles.categoryLabel} post={post} postListLayout={type} />
    );

  // HACK: for side-by-side with borders and no image
  if (!get(post, 'coverImage.shouldRender') && isSideBySideLayout) {
    if (textBoxAlignment === TEXT_PLACEMENT.SHOW_ON_THE_RIGHT) {
      containerStyle.paddingLeft = `${galleryOptions.itemBorderWidth * 2}px`;
    }
    if (textBoxAlignment === TEXT_PLACEMENT.SHOW_ON_THE_LEFT) {
      containerStyle.paddingRight = `${galleryOptions.itemBorderWidth * 2}px`;
    }
  }

  return (
    <article className={containerClassName} style={containerStyle} data-hook="post-list-item">
      <div
        style={getLayoutPaddingStyles(layoutSidesPadding, isLayoutNameTextOnImage(type))}
        className={contentWrapperClassName}
      >
        {!layoutList && isMetadataHeaderVisible && Header}
        {!layoutList && renderCategoryLabel()}
        <div className={linkContainerClass}>
          <PostLink postLink={post.link} postPath={post.slug} className={titleLinkClassName}>
            <div
              style={getTitleStyle(postTitleFontSize)}
              className={classNames(titleContainerClassName, alignmentStyles.textAlign)}
              data-hook="post-list-item__title"
            >
              <PostListItemProGalleryTitle
                type={type}
                title={post.title}
                style={titleStyle}
                lineCount={lineCount.title}
                titleLineCount={titleLineCount}
                showCategoryLabel={showCategoryLabel}
                layoutSidesPadding={layoutSidesPadding}
              />
            </div>
          </PostLink>
          {withDescription && (lineCount.description || descriptionLineCount) ? (
            <div className={excerptLinkClassName} onClick={() => navigateProGalleryWithinPostPage(post.slug)}>
              <div
                style={contentStyle}
                className={classNames(contentClassName, alignmentStyles.textAlign)}
                data-hook="post-description"
              >
                <PostListItemProGalleryExcerpt
                  post={post}
                  lineCount={lineCount.description}
                  descriptionLineCount={descriptionLineCount}
                />
              </div>
            </div>
          ) : null}
        </div>
        {layoutList && renderCategoryLabel()}
        {layoutList && Header}
        {isMetadataFooterVisible && (
          <div
            style={getLayoutPaddingStyles(layoutSidesPadding, isLayoutNameTextOnImage(type), true)}
            className={classNames(styles.footer, styles[type], styles.withoutCover, styles[section])}
          >
            {!layoutList && <HorizontalSeparatorForPostCard className={classNames(styles.separator, styles[type])} />}
            <div style={getMetadataStyle(postMetadataFontSize)}>
              <PostFooter
                type={type}
                post={post}
                onLikeClick={onLikeClick}
                displayIcons={displayFooterIcons}
                className={footerClassName}
                commentClassName={classNames(getPostClassName('link-hashtag-hover-color'))}
              />
            </div>
          </div>
        )}
      </div>
    </article>
  );
};

PostListItem.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  getPostClassName: PropTypes.func.isRequired,
  post: PropTypes.object,
  type: PropTypes.string.isRequired,
  itemConfig: PropTypes.object.isRequired,
  borderWidth: PropTypes.number,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  contentFontClassNameWithStyle: PropTypes.string.isRequired,
  postTitleFontSize: PropTypes.number.isRequired,
  postDescriptionFontSize: PropTypes.number.isRequired,
  postMetadataFontSize: PropTypes.number.isRequired,
  isMetadataFooterVisible: PropTypes.bool,
  isMetadataHeaderVisible: PropTypes.bool,
  canSeeMoreButton: PropTypes.func.isRequired,
  showPostDescription: PropTypes.bool,
  applyFeedDesign: PropTypes.bool.isRequired,
  section: PropTypes.oneOf(SECTIONS),
  index: PropTypes.number,
  layoutSidesPadding: PropTypes.number,
  hideAuthorBadge: PropTypes.bool,
  isRTL: PropTypes.bool,
  galleryOptions: PropTypes.object,
  textBoxAlignment: PropTypes.string,
  descriptionLineCount: PropTypes.number,
  titleLineCount: PropTypes.number,
  navigateProGalleryWithinPostPage: PropTypes.func.isRequired,
  shouldUsePostListMobileDescriptionFontSize: PropTypes.bool.isRequired,
  shouldUsePostListMobileTitleFontSize: PropTypes.bool.isRequired,
  isPostCardAlignmentEnabled: PropTypes.bool.isRequired,
  visibleActions: PropTypes.arrayOf(PropTypes.string),
};

PostListItem.defaultProps = {
  isMetadataFooterVisible: true,
  isMetadataHeaderVisible: true,
  canSeeMoreButton: () => false,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const isRTL = getIsRTL(state);
  const contentAlignment = getContentAlignment(state, isRTL);
  return {
    isRTL,
    shouldUsePostListMobileDescriptionFontSize: getShouldUsePostListMobileDescriptionFontSize(state),
    shouldUsePostListMobileTitleFontSize: getShouldUsePostListMobileTitleFontSize(state),
    post: ownProps.post || getPostByIdOrSlug(state, ownProps.postId),
    descriptionLineCount: getLayoutDescriptionLineCount({ state }),
    titleLineCount: getLayoutTitleLineCount({ state }),
    navigateProGalleryWithinPostPage: actions.navigateProGalleryWithinPostPage,
    alignment: getContentAlignmentStyles(contentAlignment, isRTL),
  };
};

// prettier-ignore
export default flowRight(
  withFontClassName,
  withPostFontSize,
  withFeedMetadataSettings,
  withIsFeedDesignEnabled,
  withFeedBorderWidth,
  withExperiment({
    isPostCardAlignmentEnabled: EXPERIMENT_POST_CARD_ALIGNMENT,
  }),
  connect(mapRuntimeToProps),
)(PostListItem);
